'use client';

import Image from 'next/image';
import Link from 'next/link';
import { ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';

import avatarImg from 'app/_assets/profile-img.png';
import leftIcon from 'app/_assets/left.svg';
import { useAlerts } from 'app/_context/alertContext';
import HelpContext from 'app/_context/HelpContext';
import { useLogoutUserMutation } from 'generated/hooks';
import { UserNode } from 'generated/types';
import { APP_ROUTES, resolveRoute, OWNER_ROUTES, OWNER_SIDEBAR_ROUTES, IRoutes } from 'utils/routes';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, DropdownMenuGroup } from 'components/ui/dropdown-menu';
import { CloseIcon, LogoutIcon, MenuIcon } from 'components/icons';

type HeaderProps = {
  user?: UserNode | null;
  additionalRightControl?: ReactNode;
  routes: IRoutes[];
}

export default function Header(props: HeaderProps) {
  const router = useRouter();
  const path = usePathname();

  const { toggle } = useContext(HelpContext);

  const headerRef = useRef<HTMLElement>(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [, logoutUser] = useLogoutUserMutation();
  const { success: successAlert, error: errorAlert } = useAlerts();

  const backButton = useMemo(() => APP_ROUTES.find(route => route.getPath() === path)?.hasBackButton, [path]);

  const pageTitle = useMemo(() => {
    return resolveRoute(path as string)?.title ?? '';
  }, [path]);

  const handleLogout = useCallback(async () => {
    try {
      const response = await logoutUser({});

      if (response.error) {
        return errorAlert('Oops, something went wrong. Please try again later.');
      }

      router.push(OWNER_ROUTES.HOME.getPath());
    } catch (e) {
      console.error(e);
    }
  }, [errorAlert, logoutUser, router]);

  const dropdownItems = useMemo(() => ([
    {
      icon: <LogoutIcon height={'24'} width={'24'} />,
      label: 'Logout',
      onClick: handleLogout
    }
  ]), [handleLogout]);

  return (
    <header ref={headerRef}
            className="flex justify-between items-center py-4 px-8 max-h-18 sm:max-h-16 xs:relative xs:px-3 bg-white">
      <div className="w-full flex items-center">
        {backButton && (
          <Link
            href={''}
            onClick={router.back}
            className={'mr-1'}
          >
            <Image
              src={leftIcon}
              width={25}
              height={25}
              alt={'back'}
              className={'inline-block cursor-pointer'}
            />
          </Link>
        )}
        <h1 className={'inline-block text-dark-blue text-2xl font-semibold sm:text-xl xs:text-md'}>
          {pageTitle}
        </h1>
      </div>
      {/* menu for desktop */}
      <div className="flex gap-x-6 sm:gap-x-3 md:hidden">
        {/*
        <button className="hover:bg-[#626cfe44] transition-all rounded-[50%] flex justify-center items-center bg-gree-color w-10 sm:bg-transparent">
          <Image src={searchIcon} alt="icon" />
        </button>
        <button className=" hover:bg-[#626cfe44] transition-all rounded-[50%] flex justify-center items-center w-10 bg-gree-color sm:bg-transparent  relative">
          <Image src={notification} alt="icon" />
          {areNewMessages ? (
            <svg
              className="absolute left-[55%] top-[25%] sm:top-[10%]"
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle cx="4" cy="4" r="3.5" fill="#F24545" stroke="#FEFEFE" />
            </svg>
          ) : (
            ''
          )}
        </button>
        */}
        <div className="flex gap-x-3 items-center">
          {props.additionalRightControl ?? <></>}
          <DropdownMenu>
            <DropdownMenuTrigger>
              <div className="cursor-pointer flex flex-row gap-2 items-center justify-between">
                <Image
                  className="max-w-[40px] h-auto sm:max-w-[25px] rounded-full"
                  src={props.user?.image || avatarImg}
                  alt="avatar"
                  width={25}
                  height={25}
                />
                <h3 className="text-dark-blue text-sm">{props.user?.firstName}</h3>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuGroup>
                {dropdownItems.map((item, index) => (
                  <DropdownMenuItem className="justify-center" key={index} onClick={item.onClick}>
                    <span className="text-dark-blue text-sm">{item.label}</span>
                  </DropdownMenuItem>
                ))}
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      {/* menu for mobile */}
      <div className="hidden md:flex">
        <button
          type="button"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="text-gray-100 hover:text-gray-400 focus:outline-none focus:text-gray-400"
        >
          {isMenuOpen ? (
            <CloseIcon width={24} height={24} />
          ) : (
            <MenuIcon width={24} height={24} />
          )}
        </button>
        {isMenuOpen && (
          <div
            className={`px-3 bg-white border rounded-md absolute right-0 top-16 border-gray-light z-50 w-full transition-all h-[calc(100vh-65px)]`}>
            <div
              className={`flex flex-col justify-between h-full`}
            >
              <div>
                {props.routes.map((link) => (
                  <Link
                    key={link.getPath()}
                    href={link.getPath()}
                    className={`mb-4 px-4 py-3 text-[#9095A6] flex items-center gap-x-4 transition-all w-full ${
                      path == link.getPath()
                        ? 'rounded-md bg-purple-blue text-white'
                        : ''
                    }`}
                    onClick={() => {
                      setIsMenuOpen(() => false);
                      router.push(link.getPath());
                    }}
                  >
                    {link.icon}
                    {link.title}
                  </Link>
                ))}
              </div>
              <div className={`mt-2 mb-6`}>
                <div>
                  <span className="w-full border border-light-gray block rounded-sm mt-auto"></span>
                  <button
                    className="px-4 py-3 text-gray-text flex items-center gap-x-4"
                    onClick={() => toggle()}

                  >
                    {OWNER_ROUTES.HELP.icon}
                    {OWNER_ROUTES.HELP.title}
                  </button>
                </div>
                {dropdownItems.map((item, index) => (
                  <button
                    key={index}
                    className="px-4 py-3 text-gray-text flex items-center gap-x-4"
                    onClick={item.onClick}

                  >
                    {item.icon}
                    {item.label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}
