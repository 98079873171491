'use client';

import { FC, ReactNode, useMemo } from 'react';
import Menu from './menu';
import { ContextProviders } from '../providers';
import Header from './header';
import { CommunityInfoQuery } from '../../generated/operations';
import { usePathname } from 'next/navigation';
import { MEMBER_SIDEBAR_ROUTES, OWNER_ROUTES, OWNER_SIDEBAR_ROUTES } from '../../utils/routes';
import { LandingBuilderProvider } from '../_context/LandingBuilderContext';
import { Toaster } from '../../components/ui/toaster';
import { RoleEnum, UserNode } from '../../generated/types';

interface ILayoutProps {
  user?: UserNode | null;
  community?: CommunityInfoQuery['communityInfo'],
  children: ReactNode;
}

const OWNER_ADDITIONAL_ROUTES_SUBDOMAINS = ['ai', 'fancy', 'dimat', 'y-combinator-ukraine-k0hf', 'mzp'];

export const Layout: FC<ILayoutProps> = ({ children, user, community }) => {
  const pathname = usePathname();

  const filteredOwnerRoutes = useMemo(() => {
    return OWNER_SIDEBAR_ROUTES
      .map(route => {
        if (route.getPath() === OWNER_ROUTES.MEMBERS.getPath()) {
          return {
            ...OWNER_ROUTES.MEMBERS,
            target: '_blank'
          };
        }

        return route;
      })
      .filter((route) => {
        return route.getPath() !== OWNER_ROUTES.PAYMENTS.getPath()
          || route.getPath() === OWNER_ROUTES.PAYMENTS.getPath() && OWNER_ADDITIONAL_ROUTES_SUBDOMAINS.includes(community?.subdomain ?? '');
      })
      .filter((route) => {
        return route.getPath() !== OWNER_ROUTES.MEMBERS.getPath()
          || route.getPath() === OWNER_ROUTES.MEMBERS.getPath() && OWNER_ADDITIONAL_ROUTES_SUBDOMAINS.includes(community?.subdomain ?? '');
      });
  }, [community?.subdomain]);

  const routes = useMemo(() => {
    if (user?.communityMember?.role === RoleEnum.Member) {
      return MEMBER_SIDEBAR_ROUTES;
    }

    return filteredOwnerRoutes;
  }, [filteredOwnerRoutes, user?.communityMember?.role]);

  if (
    pathname === OWNER_ROUTES.LANDING_BUILDER.getPath()
    || pathname === OWNER_ROUTES.CREATE_COMMUNITY.getPath()
    || pathname === OWNER_ROUTES.SELECT_GOAL.getPath()
    || pathname === OWNER_ROUTES.PENDING.getPath()
    || pathname === OWNER_ROUTES.START.getPath()
  ) {
    return (
      <ContextProviders>
        <LandingBuilderProvider>
          <Toaster />
          {children}
        </LandingBuilderProvider>
      </ContextProviders>
    );
  }

  return (
    <ContextProviders>
      <div className="flex h-screen">
        <Menu community={community} routes={routes} />
        <div className="flex-1 bg-gree-color overflow-auto">
          <Toaster />
          <Header
            user={user}
            routes={routes}
          />
          <div
            className={
              'p-8 md:p-4 sm:p-4 xs:p-2 h-[calc(100vh-64px)] flex'
            }
          >
            <div className="flex-1 rounded-2xl overflow-auto">
              {children}
            </div>
          </div>
        </div>
      </div>
    </ContextProviders>
  );
};
