'use client';

import { useContext } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { usePathname,  } from 'next/navigation';

import communityIcon from 'components/icons/communityDefault.svg'
import { CommunityInfoQuery } from 'generated/operations';
import { IRoutes, OWNER_ROUTES, OWNER_SIDEBAR_ROUTES } from 'utils/routes';
import HelpContext from 'app/_context/HelpContext';
import MembersContext from 'app/_context/MembersContext';

type MenuProps = {
  community?: CommunityInfoQuery['communityInfo'],
  routes: (IRoutes & { target?: '_blank' })[];
}

export default function Menu(props: MenuProps) {
  const pathname = usePathname();

  const { toggle } = useContext(HelpContext)
  const { newMembersCount } = useContext(MembersContext);

  return (
    <div className='px-[15px] h-screen md:hidden sm:text-xs sm:px-3 w-[220px]'>
      <div className={'flex flex-col h-full py-3 justify-between whitespace-nowrap'}>
        <Link href={OWNER_ROUTES.PANEL.getPath()}>
          <Image src={props?.community?.logo || communityIcon} alt='logo' className='mb-[40%]' width={40} height={40} />
        </Link>

        <div className={'h-full'}>
          {props.routes.map((link) => (
            <Link
              key={link.getPath()}
              href={link.getPath()}
              target={link.target ?? '_self'}
              className={`mb-4 px-4 rounded-md py-3 active-menu-page text-[#9095A6] flex items-center justify-between transition-all  ${
                pathname === link.getPath()
                  ? ' bg-purple-blue text-white active'
                  : 'hover:bg-purple-blue hover:text-white'
              }`}
            >
              <div className="flex items-center gap-x-4">
                {link.icon}
                {link.title}
              </div>

              {link.name === 'panel' && !!newMembersCount && (
                <div className='bg-red text-white text-xs rounded-full flex h-5 w-5 justify-center items-center'>
                  <p className="font-poppins">
                    {newMembersCount}
                  </p>
                </div>
              )}
            </Link>
          ))}
        </div>
        <div>
          <span className='w-full border border-light-gray block rounded-sm mt-auto '></span>
          <button
            className='px-4 py-3 text-gray-text flex items-center gap-x-4'
            onClick={() => toggle()}

          >
            {OWNER_ROUTES.HELP.icon}
            {OWNER_ROUTES.HELP.title}
          </button>
        </div>
      </div>
    </div>
  );
}
